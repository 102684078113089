import React, { useMemo } from 'react';
import IconArrow from "@material-ui/icons/ArrowForwardIos"
import Spinner from '../Shared/Spinner';

const UserPublicSideNavContent = ({ children, isFetching, user, userId, username, currentTabIndex, privateMessagesEnabled, userIsContributor, userIsBlocked, userIsBanned, isLoggedIn, isAdmin, isModerator, isContributor, seePostsBy, seeCommentsBy, seeFavorites, updateModalShown, blockUser, makeContributor, quitContributor, banUser, unbanUser }) => {

    const contributorOrHigher = useMemo(() => isAdmin || isModerator || isContributor, [isAdmin, isModerator, isContributor]);
    const modOrHigher = useMemo(() => isAdmin || isModerator, [isAdmin, isModerator]);
    const isValidUser = useMemo(() => (user.data && user.data.username !== username), [user, username]);

    return (
        isFetching ? <Spinner loading={isFetching} /> : <>
            {isValidUser && ((privateMessagesEnabled && !userIsBlocked) || isAdmin) ?
                <div className="control_text" onClick={() => updateModalShown(true)}><p>Send a message</p></div>
                : null}
            <div className={currentTabIndex === 0 ? "control_text_selected" : "control_text"} onClick={seePostsBy}><p>See posts by<IconArrow></IconArrow></p></div>
            <div className={currentTabIndex === 1 ? "control_text_selected" : "control_text"} onClick={seeCommentsBy}><p>See comments by<IconArrow></IconArrow></p></div>
            <div className={currentTabIndex === 2 ? "control_text_selected" : "control_text"} onClick={seeFavorites}><p>See favorites<IconArrow></IconArrow></p></div>
            {isLoggedIn ? <>
                {/* Every loged user can block other user*/}
                {isValidUser ? <div className={"control_text"} onClick={blockUser}><p>{userIsBlocked ? "Unblock User for me" : "Block User for me"}</p></div> : null}

                {/* Contributor or higher options */}
                {contributorOrHigher ? <hr /> : null}
                {(contributorOrHigher && !userIsContributor && isValidUser) && <div className={"control_text_admin"} onClick={() => makeContributor(userId)}><p>Make contributor</p></div>}

                {/* This is not an option, but lower than admins can't remove contributors*/}
                {((isModerator || isContributor) && userIsContributor) && <div className={"control_text_disabled"}><p>User is contributor</p></div>}

                {/* Only admins can remove contributors */}
                {((isAdmin) && isValidUser && userIsContributor) && <div className={"control_text_admin"} onClick={() => quitContributor(userId)}><p>Remove contributor</p></div>}

                {/* No longer used*/}
                {/* {(isModerator) && userIsBanned ? <div className="control_text_disabled"><p>User Banned</p></div> : null} */}

                {/* Mod or higher options */}
                {modOrHigher && !userIsBanned && isValidUser ? <div className="control_text_admin" onClick={() => banUser(username, userId)}><p>Ban user</p></div> : null}
                {(modOrHigher) && userIsBanned ? <div className="control_text_admin" onClick={() => unbanUser(userId)}><p>Remove Ban</p></div> : null}
            </> : null}

            {children}
        </>
    );
};

export default UserPublicSideNavContent;