import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from "react-router-dom";
import { pageQuery } from "../../util/misc";
import SideNav from "../Nav/SideNav";
import { mobileRes } from '../Nav/SideNav';
import SearchIcon from '@material-ui/icons/Search';
import internal_fetch from "../../util/local-api";
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Select from 'react-select';
import "./PostControls.css";
const { parseQuery, defaultsLATEST, constructQueryURL, sorts, dates } = pageQuery;

const colourStyles = {
    control: styles => ({
        ...styles, backgroundColor: '#3f3e3e5c', minHeight: "0px", padding: "0px", width: "250px", color: "white", boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: "#3f3e3e5c",
            color: "white",
            cursor: isDisabled ? 'not-allowed' : 'default',

        };
    },
    dropdownIndicator: (styles) => {
        return {
            ...styles,
            padding: "0px",
            paddingRight: "5px"
        }
    },
    menu: (styles) => {
        return {
            ...styles,
            width: "250px",
            backgroundColor: "#3f3e3e",
            boxShadow: "6px 6px 4px -1px rgba(0, 0, 0, 0.4)"
        }
    },
    multiValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
            borderRadius: "15px",
            padding: "3px 10px 3px 10px",
            backgroundColor: "#e67e22",
        };
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    placeholder: (styles, { data }) => {
        return {
            ...styles,
            color: "white",
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: "white",
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        ':hover': {
            color: 'white',
        },
    }),
};

const PostControls = ({ windowState, user, updateUser, isFetching, selectedPage, functions: { updatePosts, updateIsFetching, updateTotalPages, updateSelectedPage } }) => {


    const history = useHistory();
    const [query, setQuery] = useState(parseQuery())
    const [allCategories, setCategories] = useState([]);
    const [allVersions, setVersions] = useState([]);
    // const [page, updatePage] = useState(0);
    const [tempSearch, updateSearch] = useState("");
    const [previousQuery, updatePreviousQuery] = useState("");
    const [mobileDrawerExpanded, updateMobileDrawerExpanded] = useState(false);
    const [fetchOptions, updateFetchOptions] = useState({})

    const mainRef = useRef(null);

    const updateInnerValue = (name) => (value) => {
        let { ...tmp } = fetchOptions;
        if (name === "sort" && value === 2) {
            tmp = { ...defaultsLATEST };
            tmp.sort = 2;
        }
        else tmp[name] = value;
        if (name === "search" && value && tmp.date === defaultsLATEST.date) {
            tmp.date = dates[5]
        }
        localStorage.setItem("fetch_options_latest", JSON.stringify(tmp));

        updateFetchOptions(tmp);
        updateSelectedPage(1);
        // updatePage(0)
    }

    const updateUrl = () => {
        var url = constructQueryURL(fetchOptions)
        if (!url.includes("latest")) {
            url = `/latest${url}`
        }
        //???????????????????
        if (previousQuery !== url) {
            history.push(url)
            updatePreviousQuery(url);
        }
    }

    const fetchPosts = async (page, cb) => {
        if (isFetching || !fetchOptions.date) return
        updateIsFetching(true);


        const data = await internal_fetch.latest_solved_posts(fetchOptions.date.value, fetchOptions.search, fetchOptions.category, sorts[fetchOptions.sort].sort, fetchOptions.versions, page * fetchOptions.limit, fetchOptions.limit);
        if (data && data.latestSolvedPosts) {
            cb(data.latestSolvedPosts.posts);
            updateTotalPages(data.latestSolvedPosts.totalPages)
        }
        else
            cb([])
        updateIsFetching(false);
    }

    useEffect(() => {
        updateFetchOptions({ ...fetchOptions, page: selectedPage })
    }, [selectedPage]); // eslint-disable-line react-hooks/exhaustive-deps

    history.listen((a, b, c) => {
        const newQuery = parseQuery();
        if (JSON.stringify(query) !== JSON.stringify(newQuery)) {
            setQuery(newQuery)
        }
    });


    useEffect(() => {
        if (windowState.windowWidth >= mobileRes) {
            updateMobileDrawerExpanded(false)
        }
    }, [windowState]);

    useEffect(() => {
        let search = defaultsLATEST.search;
        if (Object.entries(query).length > 0) {
            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaultsLATEST.sort,
                limit: query.limit != null ? query.limit : defaultsLATEST.limit,
                search: query.search != null ? query.search : defaultsLATEST.search,
                category: query.category != null ? query.category : defaultsLATEST.category,
                date: query.date != null ? query.date : defaultsLATEST.date,
                versions: query.versions != null ? query.versions : defaultsLATEST.version_number,
            })
            search = query.search ? query.search : defaultsLATEST.search;
        }
        else if (localStorage.getItem("fetch_options_latest")) {
            const options = JSON.parse(localStorage.getItem("fetch_options_latest"));
            search = options.search ? options.search : defaultsLATEST.search;

            updateFetchOptions(options);
        }
        else {
            updateFetchOptions({
                sort: query.sort != null ? query.sort : defaultsLATEST.sort,
                limit: query.limit != null ? query.limit : defaultsLATEST.limit,
                search: query.search != null ? query.search : defaultsLATEST.search,
                category: query.category != null ? query.category : defaultsLATEST.category,
                date: query.date != null ? query.date : defaultsLATEST.date,
                versions: query.versions != null ? query.versions : defaultsLATEST.version_number,
            })
            search = query.search ? query.search : defaultsLATEST.search;
        }
        updateSearch(search);
    }, [JSON.stringify(query)]); // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        (async function iife() {
            const [cats, versions] = await Promise.all([internal_fetch.get_categories(), internal_fetch.get_versions(), internal_fetch.get_statuses()])
            if (cats.categories)
                setCategories(cats.categories);
            if (versions.versions)
                setVersions(versions.versions);
        })();
    }, [])

    useEffect(() => {
        fetchPosts(selectedPage - 1, updatePosts);
        updateUrl();
    }, [JSON.stringify(fetchOptions)]); // eslint-disable-line react-hooks/exhaustive-deps

    const isOnHot = false;// fetchOptions.sort === 2;


    if (Object.entries(fetchOptions).length < 1)
        return null;
    return (<div className="post_controls_latests">

        <div className="post_controls_latests_upper" style={mobileDrawerExpanded ? isOnHot ? { height: "45px" } : { height: "190px" } : windowState.windowWidth < 880 ? { height: "20px" } : {}}>
            {
                <>
                    <SideNav user={user} updateUser={updateUser}>
                        <div className="post_controls_lower">
                            <div className="post_searchbar">
                                <input ref={mainRef} disabled={isFetching} maxLength={100} type="text" placeholder="Search..." value={tempSearch} onKeyDown={(e) => e.key === "Enter" ? updateInnerValue("search")(tempSearch) : null} onChange={(e) => updateSearch(e.target.value)} />
                                <SearchIcon onClick={() => mainRef.current.focus()} style={{ fontSize: "24px" }} />
                            </div>
                        </div>


                        <Select
                            value={fetchOptions.category ? fetchOptions.category.split(",").map((category) => { return { label: category, value: category } }) : []}
                            isMulti
                            options={allCategories.map((category) => { return { label: category, value: category } })}
                            className="basic-multi-select"
                            placeholder="All Categories"
                            styles={colourStyles}
                            onChange={(options) => {
                                if (options && options.length > 0) {
                                    const selectedCategories = options.map(p => p.value).join(",")
                                    updateInnerValue("category")(selectedCategories)
                                }
                                else
                                    updateInnerValue("category")("")

                            }}
                        />
                        <Select
                            value={fetchOptions.versions ? fetchOptions.versions.split(",").map((version) => { return { label: version, value: version } }) : []}
                            isMulti
                            options={allVersions.map((version) => { return { label: version, value: version } })}
                            className="basic-multi-select"
                            placeholder="All Versions"
                            styles={colourStyles}
                            onChange={(options) => {
                                if (options && options.length > 0) {
                                    const selectedVersions = options.map(p => p.value).join(",")
                                    updateInnerValue("versions")(selectedVersions)
                                }
                                else
                                    updateInnerValue("versions")("")

                            }}
                        />
                        <Select
                            value={fetchOptions.date ? { label: fetchOptions.date.text, value: fetchOptions.date.version } : []}
                            options={dates.map((date) => { return { label: date.text, value: date.value } })}
                            className="basic-multi-select"
                            styles={colourStyles}
                            onChange={(e) =>
                                updateInnerValue("date")(dates.find(d => d.value === +e.value))
                            }
                        />

                        <FormControlLabel
                            control={
                                <Checkbox checked={fetchOptions.showHidden} onChange={(e) => {
                                    updateInnerValue("showHidden")(e.target.checked)
                                }} value={fetchOptions.showHidden} disabled={false} />
                            }
                            label="Show Hidden Posts"
                        />
                    </SideNav>
                </>
            }
        </div>
    </div>);
}

export default PostControls;

export const mobileResValue = mobileRes;