import React, { forwardRef, useImperativeHandle, useState, useEffect, useMemo } from "react";
import { useParams, Link } from "react-router-dom";
import internal_fetch from "../../util/local-api";
import ReactTimeAgo from "react-time-ago/commonjs/ReactTimeAgo";
import Spinner from "../Shared/Spinner";
import UpvoteDisplay from "../Shared/UpvoteDisplay";
import Categories from "../Shared/Categories";
import FancyButton from "../Shared/FancyButton";
import EditModal from "./EditModal"
import { isAdmin, isModerator, isContributor } from "../../util/misc";
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import CommentIcon from '@material-ui/icons/Comment';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CommentsIcon from '@material-ui/icons/Chat';
import PostPageRichContent from "./PostPageRichContent"
import FlagIcon from '@material-ui/icons/Flag';
import ToolTip from "../Shared/ToolTip";
import { Tooltip } from '@material-ui/core';
import PostPageDropdownMenu from "./PostPageDropdownMenu";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from '@material-ui/icons/Star';
import PinIcon from './../../assets/pin.svg';
import "../Shared/PostBody.css";
import "./PostPage.css";
import { useAlert } from 'react-alert'
import { Checkbox, FormControlLabel, Select, MenuItem, makeStyles } from "@material-ui/core";
import PostComments from "./PostComments";
import CommentModal from "./CommentModal";
import { truncateText } from '../../util/misc';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
  select: {
    color: "white",
    backgroundColor: "#3f3e3e",
    textIndent: "10px",
    marginRight: "10px"
  }
}));

const PostBody = forwardRef(({ isFetching, post, isAuthor, user, updateParentWithVote, updateUser, windowState, showEdit, showDelete, functions: { updateIsFetching, updatePost, updateIsAuthor, updateShowEdit, updateShowDelete } }, ref) => {
  const [allStatuses, setStatuses] = useState([]);
  const [isRequesting, updatingIsRequesting] = useState(false);
  const [commentPanelOpen, updateCommentPanelOpen] = useState(false);
  const [commentPanelText, updateCommentPanelText] = useState("");
  const [mappedComments, updateMappedComments] = useState({});
  const [showEditPane, updateEditPane] = useState(false);
  const [postDupes, updatePostDupes] = useState([]);
  const [isValuable, updateIsValuable] = useState(false);
  const [isSubmitting, updateIsSubmitting] = useState(false);
  const [isFavourite, updateIsFavourite] = useState(false);
  const [autoFavourite, updateAutoFavourite] = useState(JSON.parse(localStorage.getItem("comment_auto_favourite")));
  const [newComment, updateNewComment] = useState({});


  const postClosed = ["solved", "rejected", "fixed_internally", "known_issue", "closed", "hidden"].includes(post.status) && user.data && !isAdmin(user.data.role)
  const [duplicatesCollapsed, updateDuplicatesCollapsed] = useState(true);


  const hasFlaggedPost = useMemo(() => post.flags && user.data && post.flags.findIndex(u => u.id === user.data.id) !== -1, [post, user]);
  const alert = useAlert()
  const classes = useStyles();

  let { id } = useParams();

  useImperativeHandle(ref, () => ({
    showComment() {
      updateCommentPanelOpen(!commentPanelOpen);
    },

    openEdit() {
      updateEditPane(true);
    },

    openDelete() {
      if (window.confirm("Really delete post?"))
        deletePost();
    },

    handleFlagPost(value) {
      flagPost(value);
    },

    handleFavourites(value) {
      updateFavourites(value);
    },

    handlePin(value) {
      updatePin(value);
    },

    handleVote(value) {
      updateUpvote(value);
    },

    handleHidePost() {
      hidePost();
    },

  }));

  useEffect(() => {
    localStorage.setItem("comment_auto_favourite", JSON.stringify(autoFavourite));
  }, [autoFavourite])

  useEffect(() => {
    if (post.duplicates && post.duplicates.length > 0) {

      const selfDupe = post.duplicates.find(p => p.post_id === post.id)
      if (selfDupe && selfDupe.valuable)
        updateIsValuable(true);

      updatePostDupes(post.duplicates.filter(dupe => dupe.post_id !== post.id).sort((a, b) => {
        const aHasVideo = a.post_title.includes("[V]")
        const bHasVideo = b.post_title.includes("[V]")

        const aHasImage = a.post_title.includes("[I]")
        const bHasImage = b.post_title.includes("[I]")

        if ((a.valuable && b.valuable) || (!a.valuable && !b.valuable)) {
          if ((aHasVideo && bHasVideo) || (!aHasVideo && !bHasVideo)) {
            if ((aHasImage && bHasImage) || (!aHasImage && !bHasImage)) {
              return b.post_upvotes - a.post_upvotes
            }
            else {
              if (aHasImage) return -1;
              if (bHasImage) return 1;
            }
          }
          else {
            if (aHasVideo) return -1;
            if (bHasVideo) return 1;
          }
        }
        else {
          if (a.valuable) return -1;
          if (b.valuable) return 1;
        }

        return 0;
      }))
    }

  }, [post]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.data) {
      updateIsAuthor(user.data.id === post.author?.id);
      updateShowEdit((user.data.id === post.author?.id && post.status === "open") || isAdmin(user.data && user.data.role) || isModerator(user.data && user.data.role))
      updateShowDelete(user.data.id === post.author?.id || isAdmin(user.data && user.data.role) || isModerator(user.data && user.data.role))
    }
  }, [user.data, post.author]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.isLoggedIn)
      if (Object.keys(mappedComments).length === 0) {
        if (!!user.data.upvotes_given > 0) {
          updateMappedComments(
            user.data.upvotes_given.reduce((obj, v) => {
              obj[v] = true;
              return obj;
            }, {})
          );
        }
      }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.data?.favourites && post.id) {
      updateIsFavourite(user.data.favourites.map(p => p.id).includes(post.id))
    }
  }, [post.id, user]);

  useEffect(() => {
    (async function iife() {
      const stats = await internal_fetch.get_statuses();
      if (stats.statuses)
        setStatuses(stats.statuses.map(x => Object({ key: x.split(":")[0], value: x.split(":")[1] })));
    })();
  }, [])

  useEffect(() => {
    if (!isFetching) {
      if (user.data !== undefined && post.id === undefined)
        getPost();
    }
  }, [id, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const getPost = async () => {
    updateIsFetching(true);
    const response = await internal_fetch.specific_post(id, user.data && isAdmin(user.data.role));
    updateIsFetching(false);
    if (response && response.post) updatePost(response.post);
    else {
      updatePost({})
    }
  }

  const updateAdminData = async (admin_data) => {
    const response = await internal_fetch.update_post_admin_data(id, admin_data.in_progress, admin_data.notes);
    if (response && response.updatePostAdminData) {
      alert.info("Updated");
    }
    else
      alert.error(response.errors);
  }

  const hidePost = async () => {
    const response = await internal_fetch.toggle_hide_post(post.id);
    if (response && response.toggleHidePost && response.toggleHidePost === "Updated.") {
      const { ...tmpPost } = post;
      tmpPost.hidden = !tmpPost.hidden

      updatePost(tmpPost);
    }
  }

  const voteOnPost = async downvote => {
    const { ...tmpPost } = post;
    if (downvote) tmpPost.upvotes -= 1;
    else tmpPost.upvotes += 1;

    updatePost(tmpPost);
    const { ...tmpMap } = mappedComments;

    if (downvote) delete tmpMap[post.id];
    else tmpMap[post.id] = true;
    updateMappedComments(tmpMap);
    updateParentWithVote(post.id, downvote);
  };

  const postComment = async () => {
    if (!commentPanelText || commentPanelText.trim().length < 1) return;

    updateIsSubmitting(true);
    const response = await internal_fetch.create_comment(
      commentPanelText,
      post.id,
      autoFavourite
    );
    updateIsSubmitting(false);
    if (response.createComment) {
      updateNewComment(response.createComment.id)
      const { ...tmpPost } = post;
      tmpPost.comments.unshift(response.createComment);
      updatePost(tmpPost);
      const { ...upvotes } = mappedComments;
      upvotes[response.createComment.id] = true;
      updateMappedComments(upvotes);
      updateCommentPanelText("");
      updateCommentPanelOpen(false);
      alert.info("New comment added")
    }
  };

  const deletePost = async () => {
    updateIsSubmitting(true);
    const response = await internal_fetch.delete_post(post.id);
    updateIsSubmitting(false);
    if (response.deletePost) {
      alert.info(response.deletePost);
      updatePost({})
    }
    else if (response.errors)
      alert.error(response.errors)
  }

  const flagPost = async (value) => {
    updateIsSubmitting(true);
    const response = value ? await internal_fetch.flag_post(post.id) : await internal_fetch.unflag_post(post.id);;
    updateIsSubmitting(false);
    if (response.flagPost || response.unflagPost) {
      const { ...tmpPost } = post;
      if (response.flagPost === "Flagged.") {
        tmpPost.flags.push({ id: user.data.id });
      }
      else if (response.unflagPost === "Unflagged.") {
        tmpPost.flags = tmpPost.flags.filter(flag => flag.id !== user.data.id);
      }

      updatePost(tmpPost);
    }
  }

  const updateStatus = async (post, status) => {
    const response = await internal_fetch.edit_post(post.id, {
      status
    });
    if (response.editPost) {
      const tmpPost = { ...post };
      tmpPost.status = status;
      updatePost(tmpPost);
    }
  }

  const updateFavourites = async (value) => {
    const { ...tmpUser } = user;
    if (value) {
      const response = await internal_fetch.favourite(post.id)
      if (response.favourite === "Favourited." && tmpUser?.data?.favourites) {
        tmpUser.data.favourites.push({ id: post.id });
      }
    }
    else {
      const response = await internal_fetch.unfavourite(post.id)
      if (response.unfavourite === "Un-Favourited." && tmpUser?.data?.favourites) {
        const index = tmpUser.data.favourites.map(p => p.id).indexOf(post.id)
        tmpUser.data.favourites.splice(index, 1)
      }
    }

    updateUser(tmpUser);
  }

  const updatePin = async (value) => {
    if (value) {
      const response = await internal_fetch.pin_post(post.id)
      if (response.pinPost === "Updated pinned.") {
        const tmpPost = { ...post };
        tmpPost.isPinned = true;
        updatePost(tmpPost);
        alert.info(response.pinPost);
      }
    }
    else {
      const response = await internal_fetch.unpin_post(post.id)
      if (response.unpinPost === "Updated unpinned.") {
        const tmpPost = { ...post };
        tmpPost.isPinned = false;
        updatePost(tmpPost);
        alert.info(response.unpinPost);
      }
    }
  }

  const updateUpvote = async (value) => {
    updatingIsRequesting(true);
    const response = await internal_fetch.vote_post(post.id, value);
    updatingIsRequesting(false);
    if (response && (response.upvotePost || response.downvotePost)) {
      voteOnPost(userVoted);
      const tmpPost = { ...post };
      tmpPost.likeGiven = response.upvotePost ? true : false;
      tmpPost.upvotes = response.upvotePost ? tmpPost.upvotes + 1 : tmpPost.upvotes - 1;
      updatePost(tmpPost);
    }
  }

  const userVoted = mappedComments[post.id];
  useMemo(() => post.comments ? post.comments.sort((a, b) => {
    if (a.isAnswer && b.isAnswer) {
      if (isAdmin(a.author.role) && isAdmin(b.author.role))
        return a.upvotes > b.upvotes ? -1 : 1;
      else if (isAdmin(a.author.role) || isAdmin(b.author.role))
        return isAdmin(a.author.role) ? -1 : 1
      else return a.upvotes > b.upvotes ? -1 : 1
    }
    else if (a.isAnswer || b.isAnswer)
      return a.isAnswer ? -1 : 1
    else {
      return a.upvotes > b.upvotes ? -1 : 1
    }
  }
  ) : [], [post.comments])


  if (isFetching) return <Spinner loading={isFetching} />;

  return Object.entries(post).length !== 0 ? (
    <div className="post_page_body">
      <div className="post_page_contents">
        <div className="post_page_header ">
          <div className="post_upvotes">
            <UpvoteDisplay
              disabled={!user.isLoggedIn || isRequesting}
              upvotes={post.upvotes}
              userVoted={post.likeGiven}
              action={async () => {
                updateUpvote(userVoted)
              }}
            />
            <div className="posted_by"> {post.author ? <Link style={{ padding: "5px 0 15px" }} to={`/user/${post.author.username}`}> {truncateText(post.author.username, 15)} </Link> : <b>deleted</b>}</div>

          </div>
          <div className="post_page_details">
            <div className="post_contents">
              <div className="post_title">
                <Link to={`/post/${post.id}`}>{post.title.length > 20 ? <Tooltip arrow placement="bottom-end" enterDelay={600} leaveDelay={200} title={post.title}><h1>{post.title}</h1></Tooltip> : <h1>{post.title}</h1>} </Link>
              </div>
              <div className="version_number">{post.version_number}</div>
              <Categories categories={post.categories} />
            </div>
            <div className='post_body_controls_div'>
              <div className="post_page_details_top">
                <div className="post_page_details_top_status"> {user.data && isAdmin(user.data.role) && !post.is_patch_note ? <div>
                <Select
                  labelId="demo-controlled-open-select-label"
                  id="demo-controlled-open-select"
                  value={post.status}
                  onChange={(e) => updateStatus(post, e.target.value)}
                  className={classes.select}
                >
                  {
                    allStatuses.map((status, i) =>
                      <MenuItem key={i} value={status.key}>{status.value}</MenuItem>)
                  }
                </Select>

                </div> : <span>Status:  <Link to={`/search?date=All Time&status=${post.status}`}>
                {post.status.toUpperCase()}
                </Link> </span>}
              {post.assignedTo ?
                user.data && isAdmin(user.data.role) ? <div> set by <Link to={`/user/${post.assignedTo.username}`}>{post.assignedTo.username}</Link></div> : <div> set by developer</div>
                : null}
                </div>
              </div>
              <div className="post_page_created"> Posted <ReactTimeAgo date={+post.creation_date} /> </div>

              {post.lastEdit !== 0 ? <div className="post_page_edited" style={{ color: "grey" }}>
                <div>Edited: <ReactTimeAgo date={+post.lastEdit} /></div>
              </div> : <div style={{ height: "16px" }}></div>}
              <div className="comments_number" style={{ textAlign: "center" }}><span>{post.countComments}</span>
                <CommentsIcon style={{ fontSize: "20px" }} />
                <StarIcon style={{ fontSize: "22px", marginTop: "-2px", display: isFavourite ? "unset" : "none" }} />
                <StarBorderIcon style={{ fontSize: "22px", marginTop: "-2px", display: !isFavourite ? "unset" : "none" }} />
                <FlagIcon style={{ fontSize: "22px", marginTop: "-2px", color: hasFlaggedPost ? "red" : "transparent", display: hasFlaggedPost ? "unset" : "none" }} />
                <img src={PinIcon} style={{ width: "17px", marginLeft: "6px", filter: "invert(1)", marginTop: "-2px", display: post.isPinned ? "unset" : "none" }} alt="Pin icon" />
              </div>
            </div>
            <div className="post_list_post_controls"><PostPageDropdownMenu post={post}
              pinOption={!post.isPinned ? () => { updatePin(!post.isPinned) } : undefined}
              unpinOption={post.isPinned ? () => { updatePin(!post.isPinned) } : undefined}
              editOption={showEdit ? () => { updateEditPane(true) } : undefined}
              deleteOption={showDelete ? () => { if (window.confirm("Really delete post?")) { deletePost(); } } : undefined}
              favouritesOption={user.isLoggedIn && !isFavourite ? () => { updateFavourites(true) } : undefined}
              unfavouritesOption={user.isLoggedIn && isFavourite ? () => { updateFavourites(false) } : undefined}
              flagPostOption={!isAuthor ? () => { flagPost(!hasFlaggedPost) } : undefined}
            /></div>

          </div>
        </div>

        {/* ADMIN THING PANEL STUFF */}
        {user.data && isAdmin(user.data.role) && post.admin_data && !post.is_patch_note ?
          <div style={{ display: "flex", flexDirection: "column", width: "100%", color: "white" }}>
            <br />
            <hr style={{ backgroundColor: "#FFFFFF1e" }} />
            <br />

            <div className="post_page_header" style={{ width: "100%", paddingLeft: "20px" }}>
              <textarea className="comment_panel_descriptor_textarea"
                style={{ width: "100%", marginRight: "10px", resize: "none" }}
                placeholder="notes..."
                value={post.admin_data.notes}
                onChange={(e) => {
                  const { ...tmpPost } = post;
                  tmpPost.admin_data.notes = e.target.value;
                  updatePost(tmpPost);
                }}
              >
              </textarea>
            </div>

            <div style={{ width: "100%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
              <div style={{ width: "100%", paddingLeft: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox checked={post.admin_data.in_progress} onChange={(e) => {
                      const { ...tmpPost } = post;
                      tmpPost.admin_data.in_progress = e.target.checked
                      updatePost(tmpPost)
                    }} value={post.admin_data.in_progress} disabled={isFetching} />
                  }
                  label="Internally In Progress"
                />
              </div>
              <div className="comment_buttons"><FancyButton text="Save" onClick={() => updateAdminData(post.admin_data)} /></div>
            </div>
          </div>

          : null}

        <hr />

        <PostPageRichContent
          post={post}
        />
        <div className="post_page_controls">

          {user.isLoggedIn ?
            <div className="post_page_controls_right">
              <div className="comment_option_bottom">
                <ToolTip
                Icon={CommentIcon}
                onClick={postClosed ? null : () => updateCommentPanelOpen(!commentPanelOpen)}
                text={postClosed ? "Post Closed" : "Comment"}
                disabled={postClosed}
                color={postClosed ? "grey" : "white"}
                />
              </div>

              {
                isAdmin(user.data.role) || isModerator(user.data.role) || isContributor(user.data.role) ?
                  <>
                    <ToolTip
                      Icon={AccountTreeIcon}
                      onClick={async () => {
                        const url = window.prompt("Enter Duplicate Post URL", "Add Duplicate");
                        const dupePostId = url?.split('/post/').pop();

                        if (!dupePostId) return
                        updateIsSubmitting(true);
                        const response = await internal_fetch.add_duplicate(post.id, dupePostId);
                        updateIsSubmitting(false);
                        if (response.addDuplicate)
                          alert.info("Duplicate Added.");
                        else if (response.errors)
                          alert.error(response.errors)
                      }}
                      text="Add Dupe"
                      color={"white"}
                    />
                    {
                      postDupes.length > 0 && (isAdmin(user.data.role) || isModerator(user.data.role)) ?
                        <>
                          <ToolTip
                            Icon={AccountTreeIcon}
                            onClick={async () => {
                              updateIsSubmitting(true);
                              const response = await internal_fetch.remove_duplicate(post.id);
                              updateIsSubmitting(false);
                              if (response.removeDuplicate)
                                alert.info("Removed.");
                              else if (response.errors)
                                alert.error(response.errors)
                            }}
                            text="Remove as Dupe"
                            color="red"
                          />
                          {
                            !isValuable ?
                              <ToolTip
                                Icon={AccountTreeIcon}
                                onClick={async () => {
                                  updateIsSubmitting(true);
                                  const response = await internal_fetch.update_duplicate_valuable(post.id, true);
                                  updateIsSubmitting(false);
                                  if (response.updateDuplicateValuable)
                                    alert.info("Updated.");
                                  else if (response.errors)
                                    alert.error(response.errors)
                                }}
                                text="Mark as Valuable"
                                color="cyan"
                              /> : <ToolTip
                                Icon={AccountTreeIcon}
                                onClick={async () => {
                                  updateIsSubmitting(true);
                                  const response = await internal_fetch.update_duplicate_valuable(post.id, false);
                                  updateIsSubmitting(false);
                                  if (response.updateDuplicateValuable)
                                    alert.info("Updated.");
                                  else if (response.errors)
                                    alert.error(response.errors)
                                }}
                                text="Unmark as Valuable"
                                color="green"
                              />
                          }
                        </> : null
                    }
                  </> : null
              }
            </div>
            : null}
        </div>
        {
          postDupes.length > 0 ? <div className="post_page_duplicates">
            <div>
              {duplicatesCollapsed ? <ArrowDropDownIcon style={{ fontSize: "60px" }} onClick={() => updateDuplicatesCollapsed(!duplicatesCollapsed)} /> : <ArrowDropUpIcon style={{ fontSize: "60px" }} onClick={() => updateDuplicatesCollapsed(!duplicatesCollapsed)} />}
              <h1>Duplicate Posts ({postDupes.length})</h1>
            </div>
            {
              duplicatesCollapsed ? null : <ul>
                {postDupes.map((dupe, i) =>
                  <li className="post_page_duplicate" key={i}>
                    <Link to={`/post/${dupe.post_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ fontWeight: dupe.valuable ? 800 : 400 }}>{dupe.post_title} </Link>
                  </li>)
                }
              </ul>
            }
          </div> : null
        }
      </div>
      <div className="post_comments">
        <div className="post_comments_header"><h1>Comments</h1></div>
        <PostComments
          newComment={newComment}
          user={user}
          post={post}
          mappedComments={mappedComments}
          postClosed={postClosed}
          triggerUpdate={getPost}
          voteComment={(data, downvote) => {
              const { ...upvotes } = mappedComments;
              if (downvote) delete upvotes[data.id];
              else upvotes[data.id] = true;
              updateMappedComments(upvotes);
          }} />
      </div>
      <CommentModal post={post} modalShown={commentPanelOpen} updateModal={updateCommentPanelOpen} commentPanelText={commentPanelText} isSubmitting={isSubmitting} isFetching={isFetching} autoFavourite={autoFavourite} functions={{ postComment, updateCommentPanelText, updateCommentPanelOpen, updateAutoFavourite }}></CommentModal>
      <EditModal windowState={windowState} post={post} triggerUpdate={getPost} updateModal={updateEditPane} modalShown={showEditPane} user={user} />
    </div >
  ) : (
    <div className="post_page_body"><h1 className="nice_h1">No post found.</h1></div>
  );
});

export default PostBody;