import React, { useState, useEffect } from "react";
import ExpandableControl from "../../Shared/ExpandableControl";
import internal_fetch from "../../../util/local-api";
import FancyButton from "../../Shared/FancyButton";
import { useAlert } from 'react-alert'
import styled from 'styled-components'
import ToolTip from "../../Shared/ToolTip";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import NewTable from "../NewStatusesTable"

const AdminStatuses = ({ defaultExpanded }) => {
  const [fetchedStatuses, updateStatuses] = useState([]);
  const [newEntry, newStatus] = useState(({ key: '', value: '' }));
  const alert = useAlert()

  useEffect(() => {
    (async function iife() {
      loadData();
    })();
  }, []);

  const loadData = async () => {
    updateStatuses([]);
    const response = await internal_fetch.get_statuses();

    if (response.statuses)
      updateStatuses(response.statuses.map(x => ({ key: x.split(":")[0], value: x.split(":")[1], visibility: x.split(":")[2] === 'true' })));
  }

  const update_statuses = async (key, value, visibility) => {
    updateStatuses(fetchedStatuses
      .map(x => ({
        key: x.key,
        value: key === x.key ? value : x.value,
        visibility: key === x.key ? (visibility) : x.visibility
      })))
  };

  const save_statuses = async () => {
    const response = await internal_fetch.update_statuses(
      fetchedStatuses.map(x => `${x.key}:${x.value}:${x.visibility}`)
    );
    if (response.updateStatuses) alert.info("Updated");
    else if (response.errors)
      alert.error(response.errors)
  };

  const update_new = async (key, value) => {
    newStatus(({ key, value }))
  };

  const add_statuses = async () => {
    var newArray = [({ key: newEntry.key, value: newEntry.value, visibility: true })];
    newArray = fetchedStatuses.concat(newArray);
    updateStatuses(newArray)
    newStatus(({ key: '', value: '' }))
  };

  const add_new_status = async (key, value) => {
    var newArray = [({ key: key, value: value, visibility: true })];
    newArray = fetchedStatuses.concat(newArray);
    const response = await internal_fetch.update_statuses(
      newArray.map(x => `${x.key}:${x.value}:${x.visibility}`)
    );
    if (response.updateStatuses) {
      alert.info("Updated");
      updateStatuses(newArray)
      newStatus(({ key: '', value: '' }))
    }
    else if (response.errors)
      alert.error(response.errors)
  };

  const remove_statuses = async (key) => {
    let newArray = fetchedStatuses.filter(x => x.key !== key);
    updateStatuses(newArray)
  };

  const save_given_statuses = async (value) => {
    const response = await internal_fetch.update_statuses(
      value.map(x => `${x.key}:${x.value}:${x.visibility}`)
    );
    if (response.updateStatuses) {
      alert.info("Updated");
      updateStatuses(value);
      newStatus(({ key: '', value: '' }))
    }
    else if (response.errors)
      alert.error(response.errors)
  };

  if (defaultExpanded) {
    return <NewTable rows={fetchedStatuses} rowlabel={"Statuses"} update_statuses={update_statuses} reload={loadData} save_statuses={save_statuses} updateStatuses={updateStatuses} add_statuses={add_new_status} save_given_statuses={save_given_statuses} />
  }

  return (
    <ExpandableControl
      className="admin_IP"
      height="490px"
      header="Statuses labels"
      defaultExpanded={defaultExpanded}
    >
      <div className="admin_section_details admin_statuses_section_details">
        <label>Label to be shown on dropdowns and menus:</label>

        <div >
          <Styles>
            <table>
              <thead>
                <tr>
                  <th>Status type</th>
                  <th>Label</th>
                  <th>Public</th>
                </tr>
              </thead>
              <tbody>
                {fetchedStatuses.map((status, i) =>
                  <tr key={i}>
                    <th>
                      <div className="table_controls_start"><ToolTip Icon={DeleteForeverIcon} size="24px" onClick={() => {
                        if (window.confirm(`Really delete "${status.value}" status?`))
                          remove_statuses(status.key);
                      }} text="Delete" />
                        <p>{status.key}</p>
                      </div>
                    </th>

                    <th>
                      <input type="text" id={status.key} value={status.value}
                        onChange={e => update_statuses(status.key, e.target.value, status.visibility)} />
                    </th>
                    <th>
                      <input type="checkbox" id={status.key} checked={status.visibility} onChange={e => update_statuses(status.key, status.value, e.target.checked)} />
                    </th>
                  </tr>)
                }
                <tr key="nuevo">
                  <th className="table_controls_start_input"><input type="text" value={newEntry.key} onChange={e => update_new(e.target.value, newEntry.value)} /></th>
                  <th>
                    <input type="text" value={newEntry.value} onChange={e => update_new(newEntry.key, e.target.value)} />
                  </th>
                  <th>
                    <FancyButton
                      disabled={newEntry.key.trim().length === 0 && newEntry.value.trim().length === 0}
                      onClick={add_statuses}
                      text="Add new"
                    />
                  </th>
                </tr>
              </tbody>
            </table>
          </Styles>
        </div>
        <br />
        <FancyButton
          disabled={fetchedStatuses === ""}
          onClick={save_statuses}
          text="Save"
        />
      </div>
    </ExpandableControl>
  );
};

const Styles = styled.div`
  padding: 1rem;
  overflow: auto;
  height: 100%;
  width: 100%;  
  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.4rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      text-align: center;
      :last-child {
        border-right: 0;
      }
    }
  }
`

export default AdminStatuses;